import React from 'react'
import { useCallback } from 'react';
import {Create, SaveButton, SimpleForm, Toolbar, useTranslate, useMutation, useRedirect } from 'react-admin'
import {makeStyles, Theme, withStyles} from '@material-ui/core/styles'
import {Styles} from '@material-ui/styles/withStyles'
import UserChangePasswordContent from "./UserChangePasswordContent";



export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  main: {
    marginTop: '2em',
    verticalAlign: 'top',
    overflow: 'inherit',
    paddingBottom: '10px',
  },
  pt: { paddingTop: '2em' },
  text: {
    color: 'rgb(145, 155, 174, 1)',
    fontSize: '15px',
    paddingLeft: '30px',
  },
}

const cardStyles = {
  main: {
    marginTop: '0em',
    verticalAlign: 'top',
    overflow: 'inherit',
    display: 'block',
  },
  card: {
    overflow: 'inherit',
  },
}

const useStyles = makeStyles(styles)

const validatePassword = (values: any) => {
  const errors: any = {};
  if (values.password !== values.password2) {
    errors.password2 = 'The password must be same.';
  }
  return errors
};

const UserSavePasswordToolbar = (props: any) => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const CustomEdit = withStyles(cardStyles)((props) => {
  // @ts-ignore
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const save = useCallback(
    async (values) => {
      try {
        await mutate({
          type: 'create',
          resource: 'changePassword',
          payload: { data: values },
        }, { returnPromise: true });
        redirect('/dashboard');
      } catch (error: any) {
        if (error.body.errors) {
          return error.body.errors;
        }
      }
    },
    [mutate],
  );
    return (
      <Create title='resources.users.changePassword.title' {...props}>
        <SimpleForm validate={validatePassword} save={save}>
          <UserChangePasswordContent {...props} />
        </SimpleForm>
      </Create>
    )


})

const UserChangePassword = (props: any) => {
  const classes = useStyles({})
  const translate = useTranslate()
  return (
    <>
      <div className={classes.pt}>
        <CustomEdit {...props} />
      </div>
    </>
  )
}

export default UserChangePassword
