import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useTranslate } from 'react-admin'
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
} from 'react-admin';
import PartnerField from "./PartnerField";

const ProductList = (props: ListProps) => {
    const version = useVersion()
    const dataProvider = useDataProvider()

    const [data, setData] = useState([])

    const fetchData = useCallback(async () => {
      const { data: partners } = await dataProvider.getList('partners', {pagination: {page: 1,perPage:100},filter: {},sort:{field: '', order: 'desc'} })
      setData((data) => ({ ...data, partners }))
    }, [dataProvider])

    useEffect(() => {
        fetchData()
    }, [version])

    if (data) {
        return (
          <List
            {...props}
            perPage={300}
            bulkActionButtons={false}
          >
              <Datagrid>
                  <TextField source='code' label="resources.products.data.code"/>
                  <TextField source='description' label="resources.products.data.description"/>
                  <PartnerField partners={data} />
                  <TextField source='status' label="resources.products.data.status"/>
                  <DateField source='createdAt' label="resources.products.data.createdAt"/>
                  <DateField source='updatedAt' label="resources.products.data.updatedAt"/>
                  {props.permissions?.includes('ADMIN_EDIT_PRODUCT') && <EditButton/>}
                  {props.permissions?.includes('ADMIN_DELETE_PRODUCT') && <DeleteButton undoable={false}/>}
              </Datagrid>
          </List>
        );
    }
    return <></>
};

export default ProductList;
