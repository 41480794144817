import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {PROCESSING} from "./ApplicationList";

export const ToFinishButton = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = props.record.id;
  const status = props.record.status;

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.toFinishApplication('applications', { id })
    //setData((data) => ({ ...data, session }))
    refresh();
    setOpen(false);
  };

  if (status === PROCESSING) {
    return (
      <>
        <Button label={translate('resources.applications.toFinishButton')} onClick={handleClick}/>
        <Confirm
          isOpen={open}
          title={translate('resources.applications.toFinishTitle')}
          content={translate('resources.applications.toFinishContent')}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </>
    );
  }
  return (<></>);
};