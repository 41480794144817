import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, withTypes} from 'react-final-form';
import {useLocation} from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {createTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import {Notification, useTranslate, useLogin, useNotify} from 'react-admin';

import {lightTheme} from './themes';
import {apiUrlLogin} from "../dataProvider/myDataUtils";
import decodeJwt from "jwt-decode";

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  error: {
    marginTop: '1em',
    display: 'flex',
    color: theme.palette.error.light,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));

const renderInput = ({
                       meta: {touched, error} = {touched: false, error: undefined},
                       input: {...inputProps},
                       ...props
                     }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}

  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const {Form} = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [factor, setFactor] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error: any) => {
        setLoading(false);
        if (error['type'] && error['type'] === 'SECOND_FACTOR') {
          setFactor(1);
          setErrorMessage(undefined);
        } else if (error['errorMessage']) {
          setErrorMessage(error['errorMessage']);
          //setFactor(1);
        } else {
          setFactor(0);
          setErrorMessage(undefined);
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                : error.message,
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error && error.message
                      ? error.message
                      : undefined,
              },
            }
          );
        }
      }
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const resendCode = () => {
    const storedSessionId = sessionStorage.getItem('sessionId');
    const request = new Request(apiUrlLogin + '/re-send', {
      method: 'POST',
      body: JSON.stringify({sessionId: storedSessionId}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    return fetch(request)
      .then(response => {
        if ((response.status < 200 || response.status >= 300)) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((resp) => {
        //notify send code
        notify('The code has been resent.');
      });
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon/>
                </Avatar>
              </div>
              <div className={classes.hint}>
                Login
              </div>
              {factor === 0 &&
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      // @ts-ignore
                      component={renderInput}
                      label={translate('resources.login.username')}
                      disabled={loading}
                      fullWidth
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      // @ts-ignore
                      component={renderInput}
                      label={translate('resources.login.password')}
                      type="password"
                      disabled={loading}
                      fullWidth
                    />
                  </div>
                  <div className={classes.error}>
                    {errorMessage}
                  </div>
                </div>
              }
              {factor === 1 &&
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="code"
                      // @ts-ignore
                      component={renderInput}
                      label={translate('resources.login.code')}
                      disabled={loading}
                    />
                    <Button
                      variant="contained"
                      type="button"
                      color="secondary"
                      disabled={loadingCode}
                      onClick={resendCode}
                    >
                      {loadingCode && (
                        <CircularProgress
                          size={25}
                          thickness={2}
                        />
                      )}
                      {translate('resources.login.resend')}
                    </Button>
                  </div>
                  <div className={classes.error}>
                    {errorMessage}
                  </div>
                </div>
              }
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('resources.login.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification/>
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
