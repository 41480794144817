import PagesIcon from '@material-ui/icons/Pages';

import PagesList from './PagesList';
import PageDetail from './PageDetail';
import PageEdit from './PageEdit';
import PageCreate from "./PageCreate";

export default {
    list: PagesList,
    edit: PageEdit,
    create: PageCreate,
    show: PageDetail,
    icon: PagesIcon,
};
