import * as React from 'react';
import {Admin, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './dataProvider/authProvider';
import themeReducer from './themeReducer';
import {Login, Layout} from './layout';
import customRoutes from './customRoutes';
import customSlovakMessages from './i18n/sk';
import englishMessages from './i18n/en';

import pages from './pages';
import tooltips from './tooltips';
import links from './links';
import partners from './partners';
import products from './products';
import users from './users';
import applications from './applications';
import assistances from './assistances';
import dashboard from './dashboard';
import blogs from './blogs';
import clientRefs from './clientRefs';
import changeEvents from './changeEvents';
import changePassword from './changePassword';

import dataProvider from './dataProvider/myDataProvider'
import {context} from './dataProvider/myDataUtils'

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'en') {
    return englishMessages;
  }
  if (locale === 'sk') {
    return customSlovakMessages;
  }
  // Always fallback on english
  return customSlovakMessages;
}, 'sk');

const App = () => {
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      customReducers={{theme: themeReducer}}
      customRoutes={customRoutes}
      authProvider={authProvider}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
      {permissions => [

        <Resource name='dashboard' {...dashboard} />,
        <Resource name='changePassword' create={changePassword.create} />,
        <Resource name="pages" icon={pages.icon} show={pages.show} list={pages.list} 
          create={permissions.includes('ADMIN_CREATE_PAGE') ? pages.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_PAGE') ? pages.edit : undefined}/>,
        <Resource name="tooltips" icon={tooltips.icon} show={tooltips.show} list={tooltips.list} 
          create={permissions.includes('ADMIN_CREATE_TOOLTIP') ? tooltips.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_TOOLTIP') ? tooltips.edit : undefined}/>,
        <Resource name="links" icon={links.icon} list={links.list} 
          create={permissions.includes('ADMIN_CREATE_LINK') ? links.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_LINK') ? links.edit : undefined}/>,
        <Resource name="partners" icon={partners.icon} list={partners.list} 
          create={permissions.includes('ADMIN_CREATE_PARTNER') ? partners.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_PARTNER') ? partners.edit : undefined}/>,
        <Resource name="products" icon={products.icon} list={products.list} 
          create={permissions.includes('ADMIN_CREATE_PRODUCT') ? products.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_PRODUCT') ? products.edit : undefined}/>,
        <Resource name="users" icon={users.icon} list={users.list} 
          create={permissions.includes('ADMIN_CREATE_USER') ? users.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_USER') ? users.edit : undefined}/>,
        <Resource name="applications" icon={applications.icon} list={applications.list}
          edit={permissions.includes('ADMIN_ASSIGN_APPLICATION') ? applications.edit : undefined}
          show={applications.show}/>,
        <Resource name="assistances" icon={assistances.icon} list={assistances.list}
          edit={permissions.includes('ADMIN_ASSIGN_ASSISTANCE_REQUEST') ? assistances.edit : undefined}/>,
        <Resource name="blogs" icon={blogs.icon} show={blogs.show} list={blogs.list} 
          create={permissions.includes('ADMIN_CREATE_BLOG') ? blogs.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_BLOG') ? blogs.edit : undefined}/>,
        <Resource name="clientRefs" icon={blogs.icon} show={clientRefs.show} list={clientRefs.list} 
          create={permissions.includes('ADMIN_CREATE_BLOG') ? clientRefs.create : undefined} 
          edit={permissions.includes('ADMIN_EDIT_BLOG') ? clientRefs.edit : undefined}/>,
        <Resource name="change-events" 
            icon={changeEvents.icon} 
            list={permissions.includes('ADMIN_VIEW_CHANGE_EVENTS') ? changeEvents.list : undefined}
            />
      ]}
    </Admin>
  );
};

export default App;
