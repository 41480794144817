import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useRefresh, useTranslate } from 'react-admin'
import { FunctionField } from 'react-admin';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
} from 'react-admin';
import BlogDetail from "./BlogDetail";

const BlogList = (props: ListProps) => {
      return (
          <List
            {...props}
            perPage={20}
            bulkActionButtons={false}
          >
              <Datagrid rowClick="expand" expand={<BlogDetail />} >
                  <TextField source='seq'/>
                  <FunctionField label="resources.blogs.data.title" render={(record: any) =>`${Object.values(record.title.texts)[0]}`}/>
                  <TextField source='author'/>
                  <TextField source='status'/>
                  <DateField source='createdAt'/>
                  <DateField source='updatedAt'/>
                  {props.permissions?.includes('ADMIN_EDIT_BLOG') && <EditButton />}
                  {props.permissions?.includes('ADMIN_DELETE_BLOG') && <DeleteButton undoable={false} />}
              </Datagrid>
          </List>
        );
};

export default BlogList;
