import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
  AutocompleteInput,
  Edit,
  EditProps,
  SaveButton,
  SimpleForm, TextField,
  Toolbar,
  useDataProvider,
  useTranslate,
  useVersion
} from 'react-admin';
import BackButton from "../buttons/BackButton";

const SaveToolbar = (props: any) => (
  <Toolbar {...props} >
    <BackButton />
    <SaveButton />
  </Toolbar>
);


const ApplicationEdit = (props: EditProps) => {

  const version = useVersion()
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [data, setData] = useState<any[]>([])
  const [needbrokers, setNeedbrokers] = useState(true)

  const fetchData = useCallback(async () => {
    const { data: brokers } = await dataProvider.getList('brokers', {pagination: {page: 1,perPage:100},filter: {},sort:{field: '', order: 'desc'} })

    const updatedBrokers = brokers.map(item => {
      return {
        id: item.id,
        name: item.firstName + ' ' + item.lastName
      }
    })
    setData(updatedBrokers)
  }, [dataProvider])

  useEffect(() => {
    if (props.permissions?.includes('ADMIN_VIEW_ALL_APPLICATIONS')) {
      setNeedbrokers(true)
      fetchData()
    } else {
      setNeedbrokers(false)
    }
  }, [version])

  if ((needbrokers && data && data.length > 0) || !needbrokers) {

    return (
      <Edit undoable={false} title='resources.applications.editTitle' {...props}>
        <SimpleForm redirect='list' toolbar={<SaveToolbar/>}>
          <TextField source='id' label="resources.applications.data.code"/>
          <TextField source='seq' label="resources.applications.data.seq"/>
          <TextField source='fullName' label="resources.applications.data.fullName"/>
          <TextField source='email' label="resources.applications.data.email"/>
          <TextField source='msisdn' label="resources.applications.data.msisdn"/>
          <AutocompleteInput source="assignedTo" choices={data} label={translate('resources.applications.data.assignedTo')}/>
        </SimpleForm>
      </Edit>
    );
  }
  return <></>

}

export default ApplicationEdit;

