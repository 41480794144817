import * as React from 'react';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
} from 'react-admin';
import PageDetail from "./PageDetail";

const PagesList = (props: ListProps) => (
    <List
        {...props}
        perPage={10}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="expand" expand={<PageDetail />}>
            <TextField source='code' label="resources.pages.data.code" />
            <TextField source='section' label="resources.pages.data.section" />
            <TextField source='status'  label="resources.pages.data.status" />
            <DateField source='createdAt' label="resources.pages.data.createdAt" />
            <DateField source='updatedAt' label="resources.pages.data.updatedAt" />
            {props.permissions?.includes('ADMIN_EDIT_PAGE') && <EditButton />}
            {props.permissions?.includes('ADMIN_DELETE_PAGE') && <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);

export default PagesList;
