import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useTranslate } from 'react-admin'
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
} from 'react-admin';

import {ToProcessedButton} from "./ToProcessedButton";
import {ToIgnoredButton} from "./ToIgnoredButton";

export const NEW = 'NEW';
export const PROCESSED = 'PROCESSED';
export const IGNORED = 'IGNORED';

const ChangeEventList = (props: ListProps) => {
      return (
          <List
            {...props}
            perPage={100}
            bulkActionButtons={false}
          >
              <Datagrid>
                  <TextField source='id' label="resources.changeEvents.data.id"/>
                  <TextField source='seq' label="resources.changeEvents.data.seq"/>
                  <TextField source='changeType' label="resources.changeEvents.data.changeType"/>
                  <TextField source='status' label="resources.changeEvents.data.status"/>
                  <TextField source='changeDescription' label="resources.changeEvents.data.changeDescription"/>
                  <DateField source='createdAt' label="resources.changeEvents.data.createdAt"/>
                  <DateField source='updatedAt' label="resources.changeEvents.data.updatedAt"/>
                  {props.permissions?.includes('ADMIN_CHANGE_CHANGE_EVENT_STATE') && <ToProcessedButton />}
                  {props.permissions?.includes('ADMIN_CHANGE_CHANGE_EVENT_STATE') && <ToIgnoredButton />}
              </Datagrid>
          </List>
        );


};

export default ChangeEventList;
