import {stringify} from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import {httpClient} from './myDataUtils';


/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
  let url = '';
  let typeUrl = 'admin';

  const options = {
    headers: new Headers({
      Accept: 'application/json',
    }),
  };
  switch (type) {
    case GET_LIST: {

      let from = 0;
      let pageSize = 100;
      const { page, perPage } = params.pagination;
      if (page && perPage) {
        from = (page - 1) * perPage;
        pageSize = perPage;
      }

      if (resource == 'change-events') {
        url = `/${resource}?status=NEW`;
      } else if (resource == 'clientRefs') {
        url = `/blogs?category=client-ref`;  
      } else if (resource == 'blogs') {
        url = `/blogs?category=blog`;  
      } else {
        url = `/${resource}`;
      }
      url = url.includes('?') ? url + '&' : url + '?';
      url = url + 'from=' + from + '&pageSize=' + pageSize;
      return httpClient(typeUrl, url, options).then(({json}) => {
        let items = json.data
        if (resource === 'products') {
          items = items.map( item => {
            return { ...item, configuration: JSON.stringify(item.configuration) }
          })
        }
        const data = {
          data: items,
          total: parseInt(json.recordsFiltered, 10)
        }
        return data
      })

    }
    case GET_ONE:
      if (resource == 'blogs') {
        url = `/blogs/${params.id}?langs=en,sk`;
      } else if (resource == 'clientRefs') {
        url = `/blogs/${params.id}?langs=en,sk`;
      } else if (resource == 'pages') {
        url = `/${resource}/${params.id}?langs=en,sk`;
      } else if (resource == 'tooltips') {
        url = `/${resource}/${params.id}?langs=en,sk`;
      } else if (resource == 'links') {
        url = `/${resource}/${params.id}?langs=en,sk`;
      } else {
        url = `/${resource}/${params.id}`;
      }

      return httpClient(typeUrl, url, options).then(({json}) => {
        let items = json
        if (resource === 'products') {
          items = { ...items, configuration: JSON.stringify(items.configuration) }
        }
        if (resource === 'applications') {
          let fullName = 'XXX';
          if (items.content?.client1PersonalInfo) {
            fullName = 
              (items.content?.client1PersonalInfo?.idCardFirstName ? items.content?.client1PersonalInfo?.idCardFirstName : '')
              + ' '
              + (items.content?.client1PersonalInfo?.idCardLastName ? items.content?.client1PersonalInfo?.idCardLastName : '')
          }

          items = {
            ...items,
            fullName,
            email: items.content?.emailMsisdn?.email,
            msisdn:  items.content?.emailMsisdn?.msisdn
          }
        }
        const data = {
          data: items,
        }
        return data
      })
    case CREATE:
      if (resource == 'clientRefs') {
        url = `/blogs`
      } else {
        url = `/${resource}`;
      }
      options.method = 'POST';
      if (resource === 'changePassword') {
        const userId = sessionStorage.getItem('loggedId');
        url = `/users/${userId}`;
        options.method = 'PATCH';
        const data = {
          password: params.data.password
        }
        options.body = JSON.stringify(data);
      } else if (resource === 'products' ) {
        try {
          const data = { ...params.data, configuration: JSON.parse(params.data.configuration)}
          options.body = JSON.stringify(data);
        } catch (error) {
          window.alert('Json wrong format');
          throw new Error('json parse error');
        }
      } else {
        options.body = JSON.stringify(params.data);
      }

      return httpClient(typeUrl, url, options).then(({json}) => {
        if (json) {
          const items = json
          const data = {
            data: items,
          }
          return data
        } else {
          const items = params.data;
          items.id = 'new';
          const data = {
            data: items,
          }
          return data
        }
      })
    case UPDATE:
      if (resource == 'clientRefs') {
        url = `/blogs/${params.id}`;
      } else {
        url = `/${resource}/${params.id}`;
      }
      if (resource === 'users' && params.data.hasOwnProperty('password')) {
        options.method = 'PATCH';
        const data = {
          password: params.data.password
        }
        options.body = JSON.stringify(data);
      } else if (resource === 'changePassword') {
        const userId = sessionStorage.getItem('loggedId');
        url = `/users/${userId}`;
        options.method = 'PATCH';
        const data = {
          password: params.data.password
        }
        options.body = JSON.stringify(data);
      } else if (resource === 'applications' || resource === 'assistances') {
        url = `/${resource}/${params.id}:assign`;
        const data = {
          brokerId: params.data.assignedTo
        }
        options.method = 'POST';
        options.body = JSON.stringify(data);
      } else if (resource === 'products' ) {
        try {
          const data = { ...params.data, configuration: JSON.parse(params.data.configuration)}
          options.method = 'PUT';
          options.body = JSON.stringify(data);
        } catch (error) {
          window.alert('Json wrong format');
          throw new Error('json parse error');
        }

      } else {
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
      }
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    case DELETE:
      if (resource == 'clientRefs') {
        url = `/blogs/${params.id}`;
      } else {      
        url = `/${resource}/${params.id}`;
      }
      options.method = 'DELETE';

      return httpClient(typeUrl, url, options).then(() => ({data: {result: 'OK'}}));

    case 'login': {
      const query = {
        filter: JSON.stringify({id: params.ids}),
      };
      url = `/${resource}?${stringify(query)}`;
      break;
    }
    case 'issueToken': {
      const query = {
        filter: JSON.stringify({id: params.ids}),
      };
      url = `/${resource}?${stringify(query)}`;
      break;
    }
    case 'printApplication': {
      url = `/${resource}/${params.id}:print`;
      return httpClient(typeUrl, url, options).then(({json}) => {
          let items = json
          const data = {
            data: items,
          }
        return data
      })
    }
     
    case 'toEditApplication': {
      url = `/${resource}/${params.id}:edit`;
      options.method = 'POST';
      //options.body = JSON.stringify(params.data);
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }

    case 'toProcessApplication': {
      url = `/${resource}/${params.id}:process`;
      options.method = 'POST';
      //options.body = JSON.stringify(params.data);
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }
    case 'toFinishApplication': {
      url = `/${resource}/${params.id}:finish`;
      options.method = 'POST';
      //options.body = JSON.stringify(params.data);
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }
    case 'toProcessAssistance': {
      url = `/${resource}/${params.id}:process`;
      options.method = 'POST';
      //options.body = JSON.stringify(params.data);
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }
    case 'toInProcessAssistance': {
      url = `/${resource}/${params.id}:in-process`;
      options.method = 'POST';
      //options.body = JSON.stringify(params.data);
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }    
    case 'processChangeEvent': {
      url = `/${resource}/${params.id}:process`;
      options.method = 'POST';
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }
    case 'ignoreChangeEvent': {
      url = `/${resource}/${params.id}:ignore`;
      options.method = 'POST';
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }    
    case 'resetUserPassword': {
      url = `/${resource}/${params.id}:reset-password`;
      options.method = 'POST';
      options.body = JSON.stringify({});
      return httpClient(typeUrl, url, options).then(() => {
        const data = {
          data: {...params.data, id: params.id}
        }
        return data;
      })
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return httpClient(typeUrl, url, options).then(({json}) => {
    const items = json
    const data = {
      data: items,
    }
    return data
  })

};