import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, Record } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}))

interface FieldProps {
  addLabel?: boolean
  label?: string
  record?: Record
  source?: string
  sortable?: boolean
}

interface Props extends FieldProps {
  brokers: any
}

const BrokerField: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const brokers = props.brokers?.brokers ?? []
  const record = props.record

  let result;

  if (record && record.assignedTo) {
    result = brokers.find( (item: any) => item.id === record.assignedTo)
  }

  return (
    <div className={classes.root}>
      {result?.firstName} {result?.lastName}
    </div>
  )
}

const PureBrokerField = BrokerField

PureBrokerField.defaultProps = {
  source: 'assignedTo',
  label: 'resources.assistances.data.assignedTo',
}

export default PureBrokerField
