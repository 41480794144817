import TooltipsIcon from '@material-ui/icons/Info';

import TooltipsList from './TooltipsList';
import TooltipDetail from './TooltipDetail';
import TooltipEdit from './TooltipEdit';
import TooltipCreate from "./TooltipCreate";

export default {
    list: TooltipsList,
    edit: TooltipEdit,
    create: TooltipCreate,
    show: TooltipDetail,
    icon: TooltipsIcon,
};
