import React, {useCallback, useEffect, useState} from 'react'
import {TextInput, useTranslate, SelectInput, required, useVersion, useDataProvider} from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import {Record} from "ra-core";


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  pt15: { paddingTop: '15px' },
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const requiredValidate = [required()]

const useStyles = makeStyles(styles)

const ProductCreateEditContent = (props: any) => {
  const classes = useStyles({})
  const { record } = props
  const translate = useTranslate()

  const version = useVersion()
  const dataProvider = useDataProvider()

  const [data, setData] = useState({partners:[] as Record[]})

  const fetchData = useCallback(async () => {
    const { data: partners } = await dataProvider.getList('partners', {pagination: {page: 1,perPage:100},filter: {},sort:{field: '', order: 'desc'} })
    setData((data) => ({ ...data, partners }))
  }, [dataProvider])

  useEffect(() => {
    fetchData()
  }, [version])

  if (!record || !data || !data.partners) return null

  const partnersList = data.partners.map((item: any) => {
    return {
      id: item.id,
      name: item.info
    }
  })

  return (
    <>
      <SectionTitle label='resources.products.edit.title' />
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.products.edit.code'
            source='code'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.products.edit.description'
            source='description'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <SelectInput
            fullWidth
            label='resources.products.edit.partnerId'
            source='partnerId'
            validate={requiredValidate}
            choices={partnersList}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.products.edit.configuration'
            source='configuration'
            validate={requiredValidate}
            variant='outlined'
            multiline={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.products.edit.status'
            source='status'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default ProductCreateEditContent
