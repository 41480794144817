import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useTranslate } from 'react-admin'
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    FunctionField,
    EditButton,
} from 'react-admin';

import {PrintButton} from "./PrintButton";
import {ToEditButton} from "./ToEditButton";
import {ToProcessButton} from "./ToProcessButton";
import {ToFinishButton} from "./ToFinishButton";
import ApplicationDetail from "./ApplicationDetail";
import BrokerField from "./BrokerField";

export const EDIT = 'EDIT';
export const SUBMITTED = 'SUBMITTED';
export const AWAIT_SIGN = 'AWAIT_SIGN';
export const AWAIT_SIGN_2 = 'AWAIT_SIGN_2';
export const PROCESSING = 'PROCESSING';
export const FINISHED = 'FINISHED';

const ApplicationList = (props: ListProps) => {
    const version = useVersion()
    const dataProvider = useDataProvider()
    const translate = useTranslate()

    const [data, setData] = useState([])
    const [needbrokers, setNeedbrokers] = useState(true)

    const fetchData = useCallback(async () => {
        const { data: brokers } = await dataProvider.getList('brokers', {pagination: {page: 1,perPage:100},filter: {},sort:{field: '', order: 'desc'} })
        setData((data) => ({ ...data, brokers }))
    }, [dataProvider])

    const renderChangeStateButtons  = (record: any) => (
        <span>
        {props.permissions?.includes('ADMIN_CHANGE_APPLICATION_STATE') && <ToEditButton  record={record}/> }
        {props.permissions?.includes('ADMIN_CHANGE_APPLICATION_STATE') && <ToProcessButton  record={record}/>}
        {props.permissions?.includes('ADMIN_CHANGE_APPLICATION_STATE') && <ToFinishButton record={record}/>}
        </span>
    )

    const renderStatus  = (record: any) => (
        <b>{translate('resources.applications.status.' + record.status)}</b>
    )

    useEffect(() => {
        if (props.permissions?.includes('ADMIN_VIEW_ALL_APPLICATIONS')) {
            setNeedbrokers(true)
            fetchData()
        } else {
            setNeedbrokers(false)
        }
    }, [version])

    if ((needbrokers && data) || !needbrokers) {
        return (
          <List
            {...props}
            perPage={25}
            bulkActionButtons={false} actions={false} 
          >
              <Datagrid rowClick="expand" expand={<ApplicationDetail {...props} />}>
                  <TextField source='seq' label="resources.applications.data.seq" sortable={false}/>
                  <TextField source='fullName' label="resources.applications.data.fullName" sortable={false}/>
                  <TextField source='email' label="resources.applications.data.email" sortable={false}/>
                  <TextField source='msisdn' label="resources.applications.data.msisdn" sortable={false}/>
                  <FunctionField source='status' label="resources.applications.data.status" sortable={false} render={renderStatus}/>
                  <DateField source='createdAt' label="resources.applications.data.createdAt" showTime locales="sk-SK" sortable={false}/>
                  <DateField source='updatedAt' label="resources.applications.data.updatedAt" showTime locales="sk-SK" sortable={false}/>
                  {props.permissions?.includes('ADMIN_VIEW_ALL_APPLICATIONS') && <BrokerField brokers={data} sortable={false}/>}
                  {props.permissions?.includes('ADMIN_PRINT_APPLICATION') && <PrintButton />}

                  {props.permissions?.includes('ADMIN_ASSIGN_APPLICATION') && <EditButton  label={'button.assign'} />}
                  <FunctionField label={'button.changeState'} render={renderChangeStateButtons} source="seq" sortable={false}/>
              </Datagrid>
          </List>
        );
    }
    return <></>



};

export default ApplicationList;
