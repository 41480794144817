import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useTranslate,
} from 'react-admin';

export const ResetPasswordButton = (props: any) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const id = props.record.id;

  const [open, setOpen] = useState(false);

  const handleClick = (event : any) => {
    event.stopPropagation();
    setOpen(true);
  }
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.resetUserPassword('users', { id })
    setOpen(false);
  };

  return (
    <>
      <Button label={translate('resources.users.resetPassword.button')} onClick={handleClick} />
      <Confirm
        isOpen={open}
        title={translate('resources.users.resetPassword.title')}
        content={translate('resources.users.resetPassword.content')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default ResetPasswordButton
