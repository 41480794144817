import * as React from 'react';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
} from 'react-admin';
import TooltipDetail from "./TooltipDetail";

const TooltipsList = (props: ListProps) => (
    <List
        {...props}
        perPage={20}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="expand" expand={<TooltipDetail />}>
            <TextField source='code' label="resources.tooltips.data.code" />
            <TextField source='section' label="resources.tooltips.data.section" />
            <TextField source='status' label="resources.tooltips.data.status" />
            <DateField source='createdAt' label="resources.tooltips.data.createdAt" />
            <DateField source='updatedAt' label="resources.tooltips.data.updatedAt" />
            {props.permissions?.includes('ADMIN_EDIT_TOOLTIP') && <EditButton />}
            {props.permissions?.includes('ADMIN_DELETE_TOOLTIP') && <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);

export default TooltipsList;
