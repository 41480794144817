import React from 'react'
import { TextInput, useTranslate, TranslatableInputs, SelectInput, required } from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import { RichTextInput } from '@agilie/ra-tinymce-input';


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  pt15: { paddingTop: '15px' },
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const requiredValidate = [required()]

const useStyles = makeStyles(styles)

const ClientRefCreateEditContent = (props: any) => {
  const classes = useStyles({})
  const { record } = props
  const translate = useTranslate()

  const text = (texts: any) => {
    if (texts && texts.en) {
      return texts.en
    }
    return ''
  }

  if (!record) return null
  return (
    <>
      <SectionTitle label='resources.clientRefs.edit.title' />
      <Grid container spacing={2}>
      <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.clientRefs.edit.image'
            source='image'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>        
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.clientRefs.edit.author'
            source='author'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput 
          className={classes.fullWidth}
            source="status" 
            label='resources.clientRefs.edit.status'
            validate={requiredValidate}
            choices={[
              { id: 'A', name: 'Active' },
              { id: 'D', name: 'Disabled' }
            ]} 
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput 
          className={classes.fullWidth}
            source="category" 
            label='resources.clientRefs.edit.category'
            validate={requiredValidate}
            choices={[
              { id: 'client-ref', name: 'Client Reference' }
            ]} 
          />
        </Grid>        
      </Grid>

      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TranslatableInputs locales={['sk', 'en']} defaultLocale={'sk'} >
            <TextInput
              className={classes.fullWidth}
              autoFocus
              label='resources.clientRefs.edit.titleTitle'
              source='title.texts'
              validate={requiredValidate}
              variant='outlined'
            />
          <RichTextInput source="content.texts"
             apiKey = "u40rfquqv3zbxbldqrep8br0rko0dci4n1ovy83vdwscek0w"          
             init={{
               height: 500,
               menubar: true,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount toc emoticons'
               ],
               toolbar: 'undo redo | formatselect | ' +
                 'bold italic backcolor | alignleft aligncenter ' +
                 'alignright alignjustify | bullist numlist outdent indent | ' +
                 'removeformat | help | toc | emoticons',
               content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
             }} />
          </TranslatableInputs>
        </Grid>
      </Grid>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default ClientRefCreateEditContent
