import ApplicationsIcon from '@material-ui/icons/Pages';

import ApplicationList from './ApplicationList';
import ApplicationDetail from "./ApplicationDetail";
import ApplicationEdit from "./ApplicationEdit";

export default {
    list: ApplicationList,
    icon: ApplicationsIcon,
    show: ApplicationDetail,
    edit: ApplicationEdit
};
