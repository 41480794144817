import React from 'react'
import { TextInput, useTranslate, BooleanInput, SelectInput, required, TranslatableInputs } from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import { RichTextInput } from '@agilie/ra-tinymce-input';


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  pt15: { paddingTop: '15px' },
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const requiredValidate = [required()]

const useStyles = makeStyles(styles)

const TooltipCreateEditContent = (props: any) => {
  const classes = useStyles({})
  const { record } = props
  const translate = useTranslate()

  const text = (texts: any) => {
    if (texts && texts.en) {
      return texts.en
    }
    return ''
  }

  if (!record) return null
  return (
    <>
      <SectionTitle label='resources.tooltips.edit.title' />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            autoFocus
            label='resources.tooltips.edit.code'
            source='code'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.tooltips.edit.section'
            source='section'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.tooltips.edit.status'
            source='status'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
        <TranslatableInputs locales={['sk', 'en']} defaultLocale={'sk'} >
          <RichTextInput source="content.texts"
             apiKey = "u40rfquqv3zbxbldqrep8br0rko0dci4n1ovy83vdwscek0w"
             init={{
               height: 500,
               menubar: true,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount emoticons'
               ],
               toolbar: 'undo redo | formatselect | ' +
                 'bold italic backcolor | alignleft aligncenter ' +
                 'alignright alignjustify | bullist numlist outdent indent | ' +
                 'removeformat | help | emoticons',
               content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
             }} />
        </TranslatableInputs>
      </Grid>
    </Grid>

    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default TooltipCreateEditContent
