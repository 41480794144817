import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {NEW} from "./ChangeEventList";

export const ToProcessedButton = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = props.record.id;
  const canProcess = props.record.canProcess;
  const status = props.record.status;

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.processChangeEvent('change-events', { id })
    //setData((data) => ({ ...data, session }))
    refresh();
    setOpen(false);
  };
  if (status === NEW) {
    return (
      <>
        <Button label={translate('resources.changeEvents.toProcessedButton')} onClick={handleClick} disabled={!canProcess}/>
        <Confirm
          isOpen={open}
          title={translate('resources.changeEvents.toProcessedTitle')}
          content={translate('resources.changeEvents.toProcessedContent')}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </>
    );
  }
  return (<></>);
};