import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import LabelIcon from '@material-ui/icons/Label';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  useTranslate,
  usePermissions,
  MenuItemLink,
  MenuProps,
  ReduxState,
} from 'react-admin';

import applications from '../applications';
import assistances from '../assistances';
import blogs from '../blogs';
import pages from '../pages';
import tooltips from '../tooltips';
import links from '../links';
import partners from '../partners';
import products from '../products';
import users from '../users';
import dashboard from '../dashboard';
import clientRefs from '../clientRefs';
import changeEvents from '../changeEvents';

import {AppState} from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({dense = false}: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({...state, [menu]: !state[menu]}));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {' '}
      <MenuItemLink
        to={{
          pathname: '/dashboard',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.dashboard.name`, {
          smart_count: 2,
        })}
        leftIcon={<dashboard.icon />}
        dense={dense}
      />
      {permissions?.includes('ADMIN_VIEW_PAGE') && <MenuItemLink
        to={{
          pathname: '/pages',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.pages.name`, {
          smart_count: 2,
        })}
        leftIcon={<pages.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_PAGE') ? false : true}  
      />}
      {permissions?.includes('ADMIN_VIEW_TOOLTIP') && <MenuItemLink
        to={{
          pathname: '/tooltips',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.tooltips.name`, {
          smart_count: 2,
        })}
        leftIcon={<tooltips.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_TOOLTIP') ? false : true}  
      />}
      {permissions?.includes('ADMIN_VIEW_LINK') && <MenuItemLink
        to={{
          pathname: '/links',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.links.name`, {
          smart_count: 2,
        })}
        leftIcon={<links.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_LINK') ? false : true}  
      />}
      {permissions?.includes('ADMIN_VIEW_PARTNER') && <MenuItemLink
        to={{
          pathname: '/partners',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.partners.name`, {
          smart_count: 2,
        })}
        leftIcon={<partners.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_PARTNER') ? false : true}  
      />}
      {permissions?.includes('ADMIN_VIEW_PRODUCT') && <MenuItemLink
        to={{
          pathname: '/products',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.products.name`, {
          smart_count: 2,
        })}
        leftIcon={<products.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_PRODUCT') ? false : true}   
      />}
      {permissions?.includes('ADMIN_VIEW_USER') && <MenuItemLink
        to={{
          pathname: '/users',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.users.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_USER') ? false : true}   
      />}
      {permissions?.includes('ADMIN_VIEW_APPLICATION') && <MenuItemLink
        to={{
          pathname: '/applications',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.applications.name`, {
          smart_count: 2,
        })}
        leftIcon={<applications.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_APPLICATION') ? false : true}        
      />}
      {permissions?.includes('ADMIN_VIEW_ASSISTANCE_REQUEST') && <MenuItemLink
        to={{
          pathname: '/assistances',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.assistances.name`, {
          smart_count: 2,
        })}
        leftIcon={<assistances.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_ASSISTANCE_REQUEST') ? false : true}        
      />}
      {permissions?.includes('ADMIN_VIEW_BLOG') && <MenuItemLink
        to={{
          pathname: '/blogs',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.blogs.name`, {
          smart_count: 2,
        })}
        leftIcon={<blogs.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_BLOG') ? false : true}        
       />}
       {permissions?.includes('ADMIN_VIEW_BLOG') && <MenuItemLink
        to={{
          pathname: '/clientRefs',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.clientRefs.name`, {
          smart_count: 2,
        })}
        leftIcon={<clientRefs.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_BLOG') ? false : true}
      />}
      {permissions?.includes('ADMIN_VIEW_CHANGE_EVENTS') && <MenuItemLink
        to={{
          pathname: '/change-events',
          state: {_scrollToTop: true},
        }}
        primaryText={translate(`resources.changeEvents.name`, {
          smart_count: 2,
        })}
        leftIcon={<changeEvents.icon/>}
        dense={dense}
        disabled={permissions?.includes('ADMIN_VIEW_CHANGE_EVENTS') ? false : true}
      />}     
    </div>

  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
