import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {PROCESSING, SUBMITTED} from "./ApplicationList";

export const ToProcessButton = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = props.record.id;
  const status = props.record.status;

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.toProcessApplication('applications', { id })
    //setData((data) => ({ ...data, session }))
    refresh();
    setOpen(false);
  };
  if (status === SUBMITTED) {
    return (
      <>
        <Button label={translate('resources.applications.toProcessButton')} onClick={handleClick}/>
        <Confirm
          isOpen={open}
          title={translate('resources.applications.toProcessTitle')}
          content={translate('resources.applications.toProcessContent')}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
        <br />
      </>
    );
  }
  return (<></>);
};