import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useTranslate } from 'react-admin'
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
    FunctionField
} from 'react-admin';

import {ToInProcessButton} from "./ToInProcessButton";
import {ToProcessButton} from "./ToProcessButton";
import AssistanceDetail from "./AssistanceDetail";
import BrokerField from "./BrokerField";

export const NEW = 'NEW';
export const VERIFIED = 'VERIFIED';
export const INPROCESS = 'INPROCESS';
export const PROCESS = 'PROCESS';

const AssistanceList = (props: ListProps) => {
    const version = useVersion()
    const dataProvider = useDataProvider()
    const translate = useTranslate()

    const [data, setData] = useState([])
    const [needbrokers, setNeedbrokers] = useState(true)

    const fetchData = useCallback(async () => {
        const { data: brokers } = await dataProvider.getList('brokers', {pagination: {page: 1,perPage:100},filter: {},sort:{field: '', order: 'desc'} })
        setData((data) => ({ ...data, brokers }))
    }, [dataProvider])

    const renderChangeStateButtons  = (record: any) => (
        <span>
        {props.permissions?.includes('ADMIN_CHANGE_ASSISTANCE_REQUEST_STATE') && <ToInProcessButton record={record}/>}
        {props.permissions?.includes('ADMIN_CHANGE_ASSISTANCE_REQUEST_STATE') && <ToProcessButton record={record}/>}
        </span>
    )
    const renderStatus  = (record: any) => (
        <b>{translate('resources.assistances.status.' + record.status)}</b>
    )
    useEffect(() => {
        if (props.permissions?.includes('ADMIN_VIEW_ALL_ASSISTANCE_REQUESTS')) {
            setNeedbrokers(true)
            fetchData()
        } else {
            setNeedbrokers(false)
        }
    }, [version])

    if ((needbrokers && data) || !needbrokers) {
        return (
          <List
            {...props}
            perPage={25}
            bulkActionButtons={false} actions={false} 
          >
              <Datagrid rowClick="expand" expand={<AssistanceDetail {...props} />}>
                  <TextField source='seq' label="resources.assistances.data.seq" sortable={false}/>
                  <TextField source='name' label="resources.assistances.data.name" sortable={false}/>
                  <TextField source='msisdn' label="resources.assistances.data.msisdn" sortable={false}/>
                  <TextField source='email' label="resources.assistances.data.email" sortable={false}/>
                  <FunctionField source='status' label="resources.applications.data.status" sortable={false} render={renderStatus}/>                  
                  <DateField source='createdAt' label="resources.assistances.data.createdAt" showTime locales="sk-SK" sortable={false}/>
                  <DateField source='updatedAt' label="resources.assistances.data.updatedAt" showTime locales="sk-SK" sortable={false}/>
                  {props.permissions?.includes('ADMIN_VIEW_ALL_ASSISTANCE_REQUESTS') && <BrokerField brokers={data} sortable={false}/>}
                  {props.permissions?.includes('ADMIN_ASSIGN_ASSISTANCE_REQUEST') && <EditButton label={'button.assign'}/>}

                  <FunctionField label={'button.changeState'} render={renderChangeStateButtons} source="seq" sortable={false}/>
              </Datagrid>
          </List>
        );
    }
    return <></>

};

export default AssistanceList;
