import React from 'react'
import { TextInput, useTranslate, BooleanInput, SelectInput, required } from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  pt15: { paddingTop: '15px' },
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const requiredValidate = [required()]

const useStyles = makeStyles(styles)

const PartnerCreateEditContent = (props: any) => {
  const classes = useStyles({})
  const { record } = props
  const translate = useTranslate()

  const text = (texts: any) => {
    if (texts && texts.en) {
      return texts.en
    }
    return ''
  }

  if (!record) return null
  return (
    <>
      <SectionTitle label='resources.partners.edit.title' />
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.partners.edit.code'
            source='code'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.partners.edit.info'
            source='info'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.partners.edit.link'
            source='link'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.partners.edit.logoUrl'
            source='logoUrl'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.fullWidth}>
        <Grid item xs={12}>
          <TextInput
            className={classes.fullWidth}
            label='resources.partners.edit.status'
            source='status'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default PartnerCreateEditContent
