import React from 'react'
import {TextInput, useTranslate, SelectInput, required} from 'react-admin'
import {Typography, Box, Grid} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Styles} from '@material-ui/styles/withStyles'


export const styles: Styles<Theme, any> = {
  fullWidth: {width: '100%'},
  pt15: {paddingTop: '15px'},
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const securityMethods = [
  {id: 'PWD_SMS_OTP', name: 'PWD_SMS_OTP'},
  {id: 'PWD_EMAIL_OTP', name: 'PWD_EMAIL_OTP'},
  {id: 'EMLLNK_SMS_OTP', name: 'EMLLNK_SMS_OTP'},
  {id: 'SMS_EMAIL_OTP', name: 'SMS_EMAIL_OTP'}
]

const roles = [
  {id: 'FULL_ADMIN', name: 'FULL_ADMIN'},
  {id: 'VIEW_ADMIN', name: 'VIEW_ADMIN'},
  {id: 'SALES_ADMIN', name: 'SALES_ADMIN'},
  {id: 'SALES', name: 'SALES'},
  {id: 'SALES_VIEW_ADMIN', name: 'SALES_VIEW_ADMIN'},
  {id: 'APPLICANT', name: 'APPLICANT'}
]

const requiredValidate = [required()]

const useStyles = makeStyles(styles)

const UserCreateEditContent = (props: any) => {
  const classes = useStyles({})
  const {record} = props
  const translate = useTranslate()

  const text = (texts: any) => {
    if (texts && texts.en) {
      return texts.en
    }
    return ''
  }

  if (!record) return null
  return (
    <>
      <SectionTitle label='resources.users.edit.title'/>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            autoFocus
            label='resources.users.edit.firstName'
            source='firstName'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.users.edit.lastName'
            source='lastName'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.users.edit.username'
            source='username'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            fullWidth
            label='resources.users.edit.role'
            source='role'
            validate={requiredValidate}
            choices={roles}
            variant='outlined'
            disabled={record.role == 'APPLICANT' || record.role == 'SUPER_ADMIN'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.users.edit.msisdn'
            source='msisdn'
            validate={requiredValidate}
            variant='outlined'
            disabled={!!record.id}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.users.edit.email'
            source='email'
            validate={requiredValidate}
            variant='outlined'
            disabled={!!record.id}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SelectInput
            className={classes.fullWidth}
            label='resources.users.edit.securityMethod'
            source='securityMethod'
            validate={requiredValidate}
            variant='outlined'
            choices={securityMethods}
            disabled={record.role == 'APPLICANT' || record.role == 'SUPER_ADMIN'}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            className={classes.fullWidth}
            label='resources.users.edit.status'
            source='status'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>

    </>
  )
}

const SectionTitle = ({label}: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default UserCreateEditContent
