import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import {apiUrlAdmin} from "../dataProvider/myDataUtils";

export const PrintButton = (props: any) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const id = props.record.id;

  const [open, setOpen] = useState(false);

  const handleClick = (event : any) => {
    event.stopPropagation();
    setOpen(true);
  }
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {

    fetch(apiUrlAdmin+'/applications/'+ id +':print', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Print_'+ id + '.pdf',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });

    setOpen(false);
  };

  return (
    <>
      <Button label={translate('resources.applications.printButton')} onClick={handleClick} />
      <Confirm
        isOpen={open}
        title={translate('resources.applications.printTitle')}
        content={translate('resources.applications.printContent')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};