import React, { useCallback, useState, useEffect } from 'react'
import { useVersion, useDataProvider, useTranslate } from 'react-admin'
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
    FunctionField
} from 'react-admin';
import ClientRefDetail from "./ClientRefDetail";

const ClientRefList = (props: ListProps) => {
      return (
          <List
            {...props}
            perPage={20}
            bulkActionButtons={false}
          >
              <Datagrid rowClick="expand" expand={<ClientRefDetail />}>
                  <TextField source='seq' label="resources.clientRefs.data.seq"/>
                  <FunctionField label="resources.clientRefs.data.title" render={(record: any) =>`${Object.values(record.title.texts)[0]}`}/>
                  <TextField source='author' label="resources.clientRefs.data.author"/>
                  <TextField source='status' label="resources.clientRefs.data.status"/>
                  <DateField source='createdAt' label="resources.clientRefs.data.createdAt"/>
                  <DateField source='updatedAt' label="resources.clientRefs.data.updatedAt"/>
                  {props.permissions?.includes('ADMIN_EDIT_BLOG') && <EditButton />}
                  {props.permissions?.includes('ADMIN_DELETE_BLOG') && <DeleteButton undoable={false} />}
              </Datagrid>
          </List>
        );


};

export default ClientRefList;
