import BlogsIcon from '@material-ui/icons/Pages';

import ClientRefList from './ClientRefList';
import ClientRefDetail from './ClientRefDetail';
import ClientRefEdit from './ClientRefEdit';
import ClientRefCreate from "./ClientRefCreate";

export default {
    list: ClientRefList,
    edit: ClientRefEdit,
    create: ClientRefCreate,
    show: ClientRefDetail,
    icon: BlogsIcon,
};
