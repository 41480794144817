import AssistancesIcon from '@material-ui/icons/Pages';

import AssistanceList from './AssistanceList';
import AssistanceEdit from "./AssistanceEdit";

export default {
    list: AssistanceList,
    icon: AssistancesIcon,
    edit: AssistanceEdit
};
