import ProductsIcon from '@material-ui/icons/Pages';

import ProductList from './ProductList';
import ProductEdit from './ProductEdit';
import ProductCreate from "./ProductCreate";

export default {
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreate,
    icon: ProductsIcon,
};
