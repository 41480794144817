import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    button: {
        assign: 'Assign',
        changeState: 'Change state'
    },
    validation: {
        error: {
            UserPasswordValidator: 'Wrong password characters....'
        }
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        changePassword: 'Change password',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        login: {
            username: 'Username',
            password: 'Password',
            code: 'Code',
            sign_in: 'Sign In',
            resend: 'Resend code',
        },
        dashboard: {
            name: 'Dashboard |||| Dashboard',
            welcome: 'Mortage admin',
        },
        pages: {
            name: 'Page |||| Pages',
            data: {
                code: 'Code',
                content: 'Content',
                section: 'Section',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            create: {
                title: 'Create new page',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit page',
                back: 'Back to list',
                code: 'Code',
                content: 'Content',
                section: 'Section',
                status: 'Status',
            },
        },
        blogs: {
            name: 'Blog |||| Blogs',
            data: {
                title: 'Title',
                content: 'Content',
                author: 'Author',
                image: 'Image',
                category: 'Category',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At'
            },
            create: {
                title: 'Create new blog',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit blog',
                back: 'Back to list',
                titleTitle: 'Title',
                content: 'Content',
                category: 'Category',
                image: 'Image',
                author: 'Author',
                status: 'Status',
            }
        },
        tooltips: {
            name: 'Tooltip |||| Tooltips',
            data: {
                code: 'Code',
                content: 'Content',
                section: 'Section',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            create: {
                title: 'Create new page',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit page',
                back: 'Back to list',
                code: 'Code',
                content: 'Content',
                section: 'Section',
                status: 'Status',
            },
        },
        partners: {
            partnerId: 'Partner',
            name: 'Partner |||| Partners',
            data: {
                code: 'Code',
                info: 'Info',
                link: 'Link',
                logoUrl: 'Logo URL',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                partnerId: 'Partner'
            },
            create: {
                title: 'Create new partner',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit partner',
                back: 'Back to list',
                code: 'Code',
                info: 'Info',
                link: 'Link',
                logoUrl: 'Logo URL',
                status: 'Status',
            },
        },
        products: {
            name: 'Product |||| Products',
            data: {
                code: 'Code',
                configuration: 'Configuration',
                description: 'Description',
                partnerId: 'Partner',
                status: 'Status',
                title: 'Title',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            create: {
                title: 'Create new product',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit product',
                back: 'Back to list',
                code: 'Code',
                configuration: 'Configuration',
                description: 'Description',
                partnerId: 'Partner',
                status: 'Status',
                titleInput: 'Title'
            },
        },
        users: {
            name: 'User |||| Users',
            data: {
                firstName: 'First name',
                lastName: 'Last name',
                username: 'Login name',
                role: 'Role',
                msisdn: 'Phone',
                email: 'Email',
                securityMethod: 'Security method',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            create: {
                title: 'Create new user',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit user',
                back: 'Back to list',
                firstName: 'First name',
                lastName: 'Last name',
                username: 'Login name',
                role: 'Role',
                msisdn: 'Phone',
                email: 'Email',
                securityMethod: 'Security method',
                status: 'Status',
            },
            changePassword: {
                button: 'Change password',
                title: 'Change password',
                password: 'New password',
                password2: 'Confirm password',
            },
            resetPassword: {
                button: 'Reset password',
                title: 'Reset password',
                content: 'Are you sure you want to reset password for selected user?'
            }
        },
        links: {
            name: 'Link |||| Links',
            data: {
                code: 'Code',
                internal: 'Internal',
                link: 'Link',
                content: 'Text',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                section: 'Section'
            },
            create: {
                title: 'Create new link',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit link',
                back: 'Back to list',
                code: 'Code',
                section: 'Section',
                content: 'Text',
                internal: 'Internal',
                link: 'Link',
                status: 'Status',
            },
        },
        applications: {
            name: 'Application |||| Applications',
            editTitle: 'Application assign to',
            content: "Content",
            data: {
                code: 'Code',
                status: 'Status',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                seq: 'Seq',
                fullName: 'Full name',
                email: 'Email',
                msisdn: 'Phone',
                feedbackStars: 'Feedback stars',
                assignedTo: 'Assigned to'
            },
            printTitle: 'Print application',
            printContent: 'Print application?',
            toProcessTitle: 'Change application status',
            toProcessContent: 'Do you want change status of application to PROCESSING?',
            toEditTitle: 'Change application status',
            toEditContent: 'Do you want change status of application to EDIT?',
            toFinishTitle: 'Change application status',
            toFinishContent: 'Do you want change status of application to FINISHED?',
            sendLinkTitle: 'Send application link',
            sendLinkContent: 'Do you want send login link to application?',
            sendLinkButton: 'Send link',
            toProcessButton: 'Process',
            toEditButton: 'Edit',
            assignToMeButton: 'Assign',
            assignToMeTitle: 'Assign application?',
            assignToMeContent: 'Application will be assigned to you.',
            toFinishButton: 'Finish',
            printButton: 'Print',
            viewLinkButton: 'View link',
            downloadAttachmentButton: 'Download',
            copyLinkToClipboard: {
                title : "Login Link",
                copy : "Copy & Close",
                close : "Close",
                warning : "Warning, providng link to not verified person can cause security breach."
            },
            status: {
                SUBMITTED: 'Submitted',
                EDIT: 'Edited by client',
                AWAIT_QUESTIONNAIRE_SIGN: "Awaiting signature",
                AWAIT_QUESTIONNAIRE_SIGN_2: "Awaiting signature",
                AWAIT_SIGN: "Awaiting signature",
                AWAIT_SIGN_2: "Awaiting signature",
                PROCESSING: "In process",
                FINISHED: "Finished"
            },
            attachments: {
                attachments: "Attachments",
                subType: "Subtype",
                type: "Type",
                file: "File",
                createdAt: "Created At"
            }
        },
        assistances: {
            name: 'Assistance |||| Assistances',
            editTitle: 'Assistance assign to',
            content: "Content",
            data: {
                seq: 'Seq',
                name: 'Name',
                id: 'Code',
                status: 'Status',
                email: 'Email',
                msisdn: 'Phone',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                assignedTo: 'Assigned to'
            },
            toProcessTitle: 'Change assistance status',
            toProcessContent: 'Do you want change status of assistance to PROCESSED?',
            toProcessButton: 'Process',
            toInProcessTitle: 'Change assistance status',
            toInProcessContent: 'Do you want change status of assistance to IN-PROCESS?',
            toInProcessButton: 'In Process',
            assignToMeButton: 'Assign',
            assignToMeTitle: 'Assign assistance?',
            assignToMeContent: 'Assisstance request will be assigned to you.',
            status: {
                NEW: 'Not verified',
                VERIFIED: 'Verified',
                INPROCESS: 'In process',
                PROCESSED: 'Processed',
                FAILED: 'Failed'
            }
        },
        clientRefs: {
            name: 'Client Referenece |||| Client References',
            data: {
                seq: 'Seq',
                title: 'Title',
                content: 'Content',
                author: 'Author',
                image: 'Image',
                status: 'Status',
                category: 'Category',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            create: {
                title: 'Create new client ref',
                back: 'Back to list',
            },
            edit: {
                title: 'Edit client reference',
                back: 'Back to list',
                titleTitle: 'Title',
                content: 'Content',
                category: 'Category',
                image: 'Image',
                author: 'Author',
                status: 'Status',
            },
        },
        changeEvents: {
            name: 'Change Events |||| Change Events',
            data: {
                seq: 'Seq',
                id: 'Code',
                changeType: 'Change type',
                status: 'Status',
                changeDescription: 'Change description',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
            toProcessedButton: 'Process',
            toIgnoredButton: 'Ignore',
            toProcessedTitle: 'Process change event',
            toProcessedContent: 'Do you want to process/execute this change event?',
            toIgnoredTitle: 'Ignore change event',
            toIgnoredContent: 'Do you want to ignore this change event?',
        }
    }
};

export default customEnglishMessages;
