// BackButton.js

import React from 'react'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router'
import {translate, useTranslate} from "react-admin";

const BackButton = ({ history: { goBack }, ...props }) => {
  const translate = useTranslate();
  return (
  <Button {...props} onClick={goBack}>
    {translate('ra.action.back')}
  </Button>
  )
}

export default withRouter(BackButton)