import { TranslationMessages } from 'react-admin';
import * as slovakMessages from 'ra-language-slovak';

const customSlovakMessages: TranslationMessages = {
    ...slovakMessages,
    button: {
        assign: 'Priradiť',
        changeState: 'Zmena stavu'
    },
    ra: {
        action: {
            add_filter: 'Pridaj filter',
            add: 'Pridaj',
            back: 'Späť',
            bulk_actions: '1 záznam označený |||| %{smart_count} záznamy označené |||| %{smart_count} záznamov označených',
            cancel: 'Zruš',
            clear_input_value: 'Vymazať',
            clone: 'Kopírovať',
            confirm: "Potvrdiť",
            create: 'Vytvoriť',
            delete: 'Zmazať',
            edit: 'Upraviť',
            export: 'Exportovať',
            list: 'Zoznam',
            refresh: 'Obnoviť',
            remove_filter: 'Odober filter',
            remove: 'Odstrániť',
            save: 'Uložiť',
            search: "Hľadať",
            show: 'Ukázať',
            sort: 'Zotriediť',
            undo: 'Vrátiť zmeny',
            unselect: 'Odznačiť',
            expand: 'Rozbaliť',
            close: 'Zavrieť',
            open_menu: 'Otvor menu',
            close_menu: 'Zavri menu',
        },
        boolean: {
            true: 'Áno',
            false: 'Nie',
            null: '',
        },
        page: {
            create: 'Vytvor %{name}',
            dashboard: 'Prehľad',
            edit: '%{name} #%{id}',
            error: 'Nastala chyba',
            list: 'Všetky %{name}',
            loading: 'Načítavam',
            not_found: 'Nenájdené',
            show: '%{name} #%{id}',
            empty: 'Žiadne záznamy',
            invite: 'Pozvať',
        },
        input: {
            file: {
                upload_several: 'Pretiahnite súbory pre nahranie alebo kliknite a vyberte.',
                upload_single: 'Pretiahnite súbor pre nahranie alebo kliknite a vyberte.',
            },
            image: {
                upload_several: 'Pretiahnite obrázky pre nahranie alebo kliknite a vyberte.',
                upload_single: 'Pretiahnite obrázok pre nahranie alebo kliknite a vyberte.',
            },
            references: {
                all_missing: 'Odkazované dáta neboli nájdené.',
                many_missing: 'Niektoré z príslušných odkazov už nie sú k dispozícií.',
                single_missing: 'Príslušný odkaz už nie sú k dispozícií.',
            },
            password: {
                toggle_visible: 'Skryť heslo',
                toggle_hidden: 'Ukázať heslo',
            },
        },
        message: {
            about: 'O',
            are_you_sure: 'Ste si istí?',
            bulk_delete_content: 'Ste si istí, že chcete zmazať záznam typu %{name}? |||| Ste si istí, že chcete zmazať %{smart_count} záznamy typu %{name}? |||| Ste si istí, že chcete zmazať %{smart_count} záznamov?',
            bulk_delete_title: 'Zmazať záznam |||| Zmazať %{smart_count} záznamy |||| Zmazať %{smart_count} záznamov',
            delete_content: 'Ste si istí, že chcete zmazať tento záznam?',
            delete_title: 'Zmazať %{name} #%{id}',
            details: 'Detaily',
            error: "Vyskytla sa chyba a Váša požiadavka nemohla byť naplnená.",
            invalid_form: 'Formulár nie je platný, skontrolujte chyby.',
            loading: 'Stránka sa načítava, počkajte prosím.',
            no: 'Nie',
            not_found: 'Zadali ste zlú adresu URL alebo link.',
            yes: 'Áno',
            unsaved_changes: 'Niektoré zmeny neboli uložené, naozaj chcete pokračovať?',
        },
        navigation: {
            no_results: 'Žiadne výsledky',
            no_more_results: 'Číslo strany (%{page}) je za rozsahom. Skúste predchádzajúce strany.',
            page_out_of_boundaries: 'Číslo strany (%{page}) je mimo rozsah.',
            page_out_from_end: 'Nemožno ísť za poslednú stranu',
            page_out_from_begin: 'Nemožno ísť pred prvú stranu',
            page_range_info: '%{offsetBegin}-%{offsetEnd} z %{total}',
            page_rows_per_page: 'Záznamy na strane:',
            next: 'Ďalšia',
            prev: 'Predošlá',
            skip_nav: 'Skip to content'
        },
        auth: {
            auth_check_error: 'Pre pokračovanie sa prihláste, prosím.',
            user_menu: 'Profil',
            username: 'Užívateľské meno',
            password: 'Heslo',
            sign_in: 'Prihlásiť',
            sign_in_error: 'Overenie zlyhalo, skúste to znova',
            logout: 'Odhlásiť',
        },
        notification: {
            updated: 'Záznam aktualizovaný |||| %{smart_count} záznamov aktualizovaných',
            created: 'Záznam vytvorený',
            deleted: 'Záznam zmazaný |||| %{smart_count} záznamov zmazaných',
            bad_item: 'Nesprávny záznam',
            item_doesnt_exist: 'Záznam neexistuje',
            http_error: 'Chyba komunikácie zo serverom',
            data_provider_error: 'Chyba poskytovateľa dát. Skontrolujte výstup z konzoly.',
            i18n_error: 'Chyba prekladu pre zvolený jazyk.',
            canceled: 'Akcia zrušená',
            logged_out: 'Pripojenie vypršalo. Pripojte sa znovu.',
        },
        validation: {
            required: 'Požadovaný',
            minLength: 'Musí byť najmenej %{min} znakov',
            maxLength: 'Môže byť najviac %{max} znakov',
            minValue: 'Musí byť najmenej %{min}',
            maxValue: 'Môže byť najviac %{max}',
            number: 'Musí byť číslo',
            email: 'Musí byť valídna emailová adresa',
            oneOf: 'Musí byť jedno z: %{options}',
            regex: 'Musí spĺňať špecifický formát (regexp): %{pattern}',
        },
    },
    validation: {
        error: {
            UserPasswordValidator: 'Wrong password characters....'
        }
    },
    pos: {
        search: 'Hľadať',
        configuration: 'Konfigurácia',
        changePassword: 'Zmena hesla',
        language: 'Jazyk',
        theme: {
            name: 'Téma',
            light: 'Svetlá',
            dark: 'Tmavá',
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
        },
    },
    resources: {
        login: {
            username: 'Login',
            password: 'Heslo',
            code: 'Kód',
            sign_in: 'Prihlásiť',
            resend: 'Preposlať kód',
        },
        dashboard: {
            name: 'Dashboard |||| Dashboard',
            welcome: 'Administrácia',
        },
        pages: {
            name: 'Stránka |||| Stránky',
            data: {
                code: 'Kód',
                content: 'Obsah',
                section: 'Sekcia',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            create: {
                title: 'Vytvoriť novú',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                code: 'Kód',
                content: 'Obsah',
                section: 'Sekcia',
                status: 'Stav',
            },
        },
        blogs: {
            name: 'Blog |||| Blogy',
            data: {
                title: 'Nadpis',
                content: 'Obsah',
                author: 'Autor',
                image: 'Obrázok',
                category: 'Kategória',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené'
            },
            create: {
                title: 'Vytvoriť blog',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Edit blog',
                back: 'Späť na zoznam',
                titleTitle: 'Nadpis',
                content: 'Obsah',
                category: 'Kategória',
                image: 'Obrázok',
                author: 'Autor',
                status: 'Stav',
            }
        },
        tooltips: {
            name: 'Tooltip |||| Tooltipy',
            data: {
                code: 'Kód',
                content: 'Obsah',
                section: 'Sekcia',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            create: {
                title: 'Vytvoriť nový',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                code: 'Kód',
                content: 'Obsah',
                section: 'Sekcia',
                status: 'Stav',
            },
        },
        partners: {
            partnerId: 'Partner',
            name: 'Partner |||| Partneri',
            data: {
                code: 'Kód',
                info: 'Info',
                link: 'Link',
                logoUrl: 'Logo URL',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
                partnerId: 'Partner'
            },
            create: {
                title: 'Vytvoriť',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                code: 'Kód',
                info: 'Info',
                link: 'Link',
                logoUrl: 'Logo URL',
                status: 'Stav',
            },
        },
        products: {
            name: 'Produkt |||| Produkty',
            data: {
                code: 'Kód',
                configuration: 'Konfigurácia',
                description: 'Popis',
                partnerId: 'Partner',
                status: 'Stav',
                title: 'Nadpis',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            create: {
                title: 'Vytovoriť',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                code: 'Kód',
                configuration: 'Konfigurácia',
                description: 'Popis',
                partnerId: 'Partner',
                status: 'Stav',
                titleInput: 'Nadpis'
            },
        },
        users: {
            name: 'Používateľ |||| Používatelia',
            data: {
                firstName: 'Krstné meno',
                lastName: 'Priezvisko',
                username: 'Prihlasovacie meno',
                role: 'Rola',
                msisdn: 'Tel.',
                email: 'Email',
                securityMethod: 'Bezp. metóda',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            create: {
                title: 'Vytvoriť',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                firstName: 'Krstné meno',
                lastName: 'Priezvisko',
                username: 'Prihlasovacie meno',
                role: 'Rola',
                msisdn: 'Tel.',
                email: 'Email',
                securityMethod: 'Bezp. metóda',
                status: 'Stav',
            },
            changePassword: {
                button: 'Zmeniť heslo',
                title: 'Zmeniť heslo',
                password: 'Nové heslo',
                password2: 'Potvrdiť',
            },
            resetPassword: {
                button: 'Resetovať heslo',
                title: 'Resetovať heslo',
                content: 'Naozaj chcete resetovať heslo pre zvoleného používateľa?'
            }
        },
        links: {
            name: 'Linka |||| Linky',
            data: {
                code: 'Kód',
                internal: 'Interná',
                link: 'Link',
                content: 'Text',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
                section: 'Sekcia'
            },
            create: {
                title: 'Vutvoriť',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                code: 'Kód',
                section: 'Sekcia',
                content: 'Text',
                internal: 'Interná',
                link: 'Linka',
                status: 'Stav',
            },
        },
        applications: {
            name: 'Žiadosť |||| Žiadosti',
            editTitle: 'Žiadosť priradiť na',
            content: "Obsah",
            data: {
                code: 'Kód',
                status: 'Stav',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
                seq: 'Poradie',
                fullName: 'Meno',
                email: 'Email',
                msisdn: 'Telefón',
                feedbackStars: 'Hodnotenie',
                assignedTo: 'Priradené na'
            },
            printTitle: 'Pdf náhľad',
            printContent: 'vygenerovať náhľad žiadosti?',
            toProcessTitle: 'Zmena stavu žiadosti',
            toProcessContent: 'Chcete zmeniť stav žiadosti na \'Spracovávaná\'?',
            toEditTitle: 'Zmena stavu žiadosti',
            toEditContent: 'Chcete zmeniť stav žiadosti na \'V editácií\'?',
            toFinishTitle: 'Zmena stavu žiadosti',
            toFinishContent: 'Chcete zmeniť stav žiadosti na \'Ukončená\'?',
            sendLinkTitle: 'Poslať login link',
            sendLinkContent: 'Chcete poslať login link?',
            sendLinkButton: 'Poslať link',
            toProcessButton: 'Spracovávať',
            toEditButton: 'Editovať',
            assignToMeButton: 'Priradiť',
            assignToMeTitle: 'Prirdaiť žiadost?',
            assignToMeContent: 'Žiadosť vám bude priradená.',
            toFinishButton: 'Ukončiť',
            printButton: 'Náhľad',
            viewLinkButton: 'Zobraziť link',
            downloadAttachmentButton: 'Stiahnuť',
            copyLinkToClipboard: {
                title : "Login Link",
                copy : "Skopírovať a zavrieť",
                close : "Zavrieť",
                warning : "Upozornenie, poskytnúť link neoverenej osobe môže viesť k bezpečnostnému riziku."
            },
            status: {
                SUBMITTED: 'Odoslaná',
                EDIT: 'Editovaná žiadateľom',
                AWAIT_QUESTIONNAIRE_SIGN: "Čaká na podpis žiadateľa",
                AWAIT_QUESTIONNAIRE_SIGN_2: "Čaká na podpis spolužiadateľa",
                AWAIT_SIGN: "Čaká na podpis žiadateľa",
                AWAIT_SIGN_2: "Čaká na podpis spolužiadateľa",
                PROCESSING: "V spracovaní",
                FINISHED: "Ukončená"
            },
            attachments: {
                attachments: "Prílohy",
                subType: "Podtyp",
                type: "Typ",
                file: "Súbor",
                createdAt: "Vytvorené"
            }
        },
        assistances: {
            name: 'Asistencia |||| Asistencie',
            editTitle: 'Asistenciu priradiť na',
            content: "Obsah",
            data: {
                seq: 'Poradie',
                name: 'Meno',
                id: 'Kód',
                status: 'Stav',
                email: 'Email',
                msisdn: 'Telefón',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
                assignedTo: 'Priradené na'
            },
            toProcessTitle: 'Zmena stavu asistencie',
            toProcessContent: 'Chcete zmeniť stav asistencie na \'Ukončená\'?',
            toProcessButton: 'Ukončiť',
            toInProcessTitle: 'Zmena stavu asistencie',
            toInProcessContent: 'Chcete zmeniť stav assistencie na \'Spracovávaná\'?',
            toInProcessButton: 'Spracovávať',
            assignToMeButton: 'Priradiť',
            assignToMeTitle: 'Priradiť asistanciu?',
            assignToMeContent: 'Asisstencia vám bude priradená.',
            status: {
                NEW: 'Neoverená',
                VERIFIED: 'Overená',
                INPROCESS: 'V spracovaní',
                PROCESSED: 'Spracovaná',
                FAILED: 'Neuspešná'
            }
        },
        clientRefs: {
            name: 'Referenecia klienta |||| Referencie klientov',
            data: {
                seq: 'Poradie',
                title: 'Nadpis',
                content: 'Obsah',
                author: 'Autor',
                image: 'Obrázok',
                status: 'Stav',
                category: 'Kategória',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            create: {
                title: 'Vytvoriť',
                back: 'Späť na zoznam',
            },
            edit: {
                title: 'Editovať',
                back: 'Späť na zoznam',
                titleTitle: 'Nadpis',
                content: 'Obsah',
                category: 'Kategória',
                image: 'Obrázok',
                author: 'Autor',
                status: 'Stav',
            },
        },
        changeEvents: {
            name: 'Zmenové požiadavky |||| Zmenové požiadavky',
            data: {
                seq: 'Poradie',
                id: 'Kód',
                changeType: 'Typ zmeny',
                status: 'Stav',
                changeDescription: 'Popis zmeny',
                createdAt: 'Vytvorené',
                updatedAt: 'Zmenené',
            },
            toProcessedButton: 'Sprocesovať',
            toIgnoredButton: 'Ignorovať',
            toProcessedTitle: 'Sprocesovať zmenu',
            toProcessedContent: 'Chcete sprocesovať túto zmenovú požiadavku?',
            toIgnoredTitle: 'Ignorovať zmenu',
            toIgnoredContent: 'Chcete igonorovať túto zmenovú požiadavku?',
        }
    }
};

export default customSlovakMessages;
