import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles({
  media: {
    height: '34em',
  },
})


const Welcome: FC = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <Card>

      <CardContent>
        <Typography variant='h5' component='h2'>
          {translate('resources.dashboard.welcome')}
        </Typography>

      </CardContent>
    </Card>
  )
}

export default Welcome
