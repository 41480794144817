import React from 'react'
import { TextInput, useTranslate, PasswordInput, required } from 'react-admin'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  pt15: { paddingTop: '15px' },
  name: {},
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}

const requiredValidate = [required()]

const useStyles = makeStyles(styles)



const UserChangePasswordContent = (props: any) => {
  const classes = useStyles({})

  const name = sessionStorage.getItem('name');
  const translate = useTranslate()

  const text = (texts: any) => {
    if (texts && texts.en) {
      return texts.en
    }
    return ''
  }


  return (
    <>
      <SectionTitle label='resources.users.changePassword.title' />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='caption' gutterBottom>
            {name}
          </Typography>
        </Grid>

      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PasswordInput
            className={classes.fullWidth}
            autoFocus
            label='resources.users.changePassword.password'
            source='password'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4}>
          <PasswordInput
            className={classes.fullWidth}
            label='resources.users.changePassword.password2'
            source='password2'
            validate={requiredValidate}
            variant='outlined'
          />
        </Grid>
      </Grid>


    </>
  )
}

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant='h6' gutterBottom>
      {translate(label)}
    </Typography>
  )
}


export default UserChangePasswordContent
