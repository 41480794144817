import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useTranslate,
  useRefresh,
} from 'react-admin';
import {VERIFIED} from "./AssistanceList";

export const ToInProcessButton = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = props.record.id;
  const status = props.record.status;

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.toInProcessAssistance('assistances', { id })
    //setData((data) => ({ ...data, session }))
    refresh();
    setOpen(false);
  };
  if (status === VERIFIED) {
    return (
      <>
        <Button label={translate('resources.assistances.toInProcessButton')} onClick={handleClick}/>
        <Confirm
          isOpen={open}
          title={translate('resources.assistances.toInProcessTitle')}
          content={translate('resources.assistances.toInProcessContent')}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
        <br />
      </>
    );
  }
  return (<></>);
};