import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate, Record } from 'ra-core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}))

interface FieldProps {
  addLabel?: boolean
  label?: string
  record?: Record
  source?: string
}

interface Props extends FieldProps {
  partners: any
}

const PartnerField: FC<Props> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const partners = props.partners?.partners ?? []
  const record = props.record

  let result = {};

  if (record && record.partnerId) {
    result = partners.find( (item: any) => item.id === record.partnerId)?.info
  }

  return (
    <div className={classes.root}>
      {result}
    </div>
  )
}

const PurePartnerField = PartnerField

PurePartnerField.defaultProps = {
  source: 'partnerId',
  label: 'resources.partners.partnerId',
}

export default PurePartnerField
