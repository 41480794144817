import * as React from 'react';
import {ArrayField, Datagrid, Show, ShowProps, SimpleShowLayout, TextField, DateField, FunctionField} from 'react-admin';
import {DownloadAttachmentButton} from "./DownloadAttachmentButton";

const render = (record:any) => (
  <pre>
      {JSON.stringify(record.content, null, '\t')}
  </pre>
);

const PageDetail = (props: ShowProps) => (
  <Show {...props} actions={false} title=" ">
    <SimpleShowLayout>
      <ArrayField source="attachmentRefs" label="resources.applications.attachments.attachments">
        <Datagrid>
          <TextField source="attachmentSubtype"  label="resources.applications.attachments.subType"/>
          <TextField source="attachmentType" label="resources.applications.attachments.type"/>
          <TextField source="filename"  label="resources.applications.attachments.file"/>
          <DateField source="createdAt" showTime  label="resources.applications.attachments.createdAt"/>
          {props.permissions?.includes('ADMIN_VIEW_APPLICATION_ATTACHMENT') && <DownloadAttachmentButton applicationId={props.id}/>}
        </Datagrid>
      </ArrayField>
      <FunctionField label="resources.applications.content"  render={render} />
    </SimpleShowLayout>
  </Show>
);

export default PageDetail;

