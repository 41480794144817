import BlogsIcon from '@material-ui/icons/Pages';

import BlogList from './BlogList';
import BlogDetail from './BlogDetail';
import BlogEdit from './BlogEdit';
import BlogCreate from "./BlogCreate";

export default {
    list: BlogList,
    edit: BlogEdit,
    create: BlogCreate,
    show: BlogDetail,
    icon: BlogsIcon,
};
