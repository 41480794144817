import React from 'react'
import { Create, SimpleForm, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PageCreateEditContent from './PageCreateEditContent'


export const styles: Styles<Theme, any> = {
  fullWidth: { width: '100%' },
  main: {
    marginTop: '2em',
    verticalAlign: 'top',
    overflow: 'inherit',
    paddingBottom: '10px',
  },
  pt: { paddingTop: '2em' },
  text: {
    color: 'rgb(145, 155, 174, 1)',
    fontSize: '15px',
    paddingLeft: '30px',
  },
}

const cardStyles = {
  main: {
    marginTop: '0em',
    verticalAlign: 'top',
    overflow: 'inherit',
    display: 'block',
  },
  card: {
    overflow: 'inherit',
  },
}

const useStyles = makeStyles(styles)


const CustomCreate = withStyles(cardStyles)((props) => (
  <Create title='resources.pages.create.title' {...props}>
    <SimpleForm redirect='list'>
      <PageCreateEditContent {...props} />
    </SimpleForm>
  </Create>
))

const PageCreate = (props: any) => {
  const classes = useStyles({})
  const translate = useTranslate()
  return (
    <>
      <div>
        <Link to='/pages'>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className={classes.text}>{translate('resources.pages.create.back')}</span>
      </div>
      <div className={classes.pt}>
        <CustomCreate {...props} />
      </div>
    </>
  )
}

export default PageCreate
