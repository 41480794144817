import * as React from 'react';
import {ArrayField, Datagrid, Show, ShowProps, SimpleShowLayout, TextField, FunctionField} from 'react-admin';
const render = (record:any) => (
  <pre>
      {JSON.stringify(record.content, null, '\t')}
  </pre>
);
const PageDetail = (props: ShowProps) => (
  <Show {...props} actions={false} title=" ">
    <SimpleShowLayout>
      <FunctionField label="resources.assistances.content" render={render} />
    </SimpleShowLayout>
  </Show>
);

export default PageDetail;

