import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useTranslate,
  useRefresh,
} from 'react-admin';
import {INPROCESS} from "./AssistanceList";

export const ToProcessButton = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const id = props.record.id;
  const status = props.record.status;

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async() => {
    const result = await dataProvider.toProcessAssistance('assistances', { id })
    refresh();
    setOpen(false);
  };
  if (status === INPROCESS) {
    return (
      <>
        <Button label={translate('resources.assistances.toProcessButton')} onClick={handleClick}/>
        <Confirm
          isOpen={open}
          title={translate('resources.assistances.toProcessTitle')}
          content={translate('resources.assistances.toProcessContent')}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </>
    );
  }
  return (<></>);
};