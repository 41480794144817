import { useState } from 'react';
import {
  Button,
  Confirm,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import {apiUrlAdmin} from "../dataProvider/myDataUtils";

export const DownloadAttachmentButton = (props: any) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const getFileName = (subtype: string, contentType: string) => {
    const spl = contentType.split('/');
    const extension = spl?.[1] || 'jpg';
    return subtype + '.' + extension;
  }

  const id = props.record.id;
  const contentType = props.record.contentType;
  const subtype = props.record.attachmentSubtype;
  const fileName = props.record.filename || getFileName(subtype, contentType);
  const applicationId = props.applicationId;

  const handleDownload = () => {
    fetch(apiUrlAdmin+'/applications/'+ applicationId +'/attachments/'+ id, {
      method: 'GET',
      headers: {
        'Content-Type': contentType,
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <>
      <Button label={translate('resources.applications.downloadAttachmentButton')} onClick={handleDownload} />
    </>
  );
};