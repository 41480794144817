import UsersIcon from '@material-ui/icons/Person';

import UsersList from './UsersList';
import UserEdit from './UserEdit';
import UserCreate from "./UserCreate";

export default {
    list: UsersList,
    edit: UserEdit,
    create: UserCreate,
    icon: UsersIcon,
};
