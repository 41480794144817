import { fetchUtils } from 'react-admin'

export const apiUrlAdmin = process.env.REACT_APP_API_ROOT + process.env.REACT_APP_API_ADMIN;
export const apiUrlLogin = process.env.REACT_APP_API_ROOT + process.env.REACT_APP_API_LOGIN;

export const context = process.env.REACT_APP_CONTEXT_ROOT


const fetchJson = (typeUrl, url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  if (typeUrl === 'login') {
    return fetchUtils.fetchJson(apiUrlLogin + url, options);
  }

  // add your own headers here 'Authorization: Basic a2FyYWY6a2FyYWY='
  options.headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
  options.headers.set('Accept-Language', 'sk_SK')
  return fetchUtils.fetchJson(apiUrlAdmin + url, options);

}

export const httpClient = fetchJson
