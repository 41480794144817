import * as React from 'react';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton,
} from 'react-admin';

const PartnersList = (props: ListProps) => (
    <List
        {...props}
        perPage={100}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField source='code' label="resources.partners.data.code" />
            <TextField source='info' label="resources.partners.data.info" />
            <TextField source='link' label="resources.partners.data.link" />
            <TextField source='logoUrl' label="resources.partners.data.logoUrl" />
            <TextField source='status' label="resources.partners.data.status" />
            <DateField source='createdAt' label="resources.partners.data.createdAt" />
            <DateField source='updatedAt' label="resources.partners.data.updatedAt" />
            {props.permissions?.includes('ADMIN_EDIT_PARTNER') && <EditButton />}
            {props.permissions?.includes('ADMIN_DELETE_PARTNER') && <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);

export default PartnersList;
