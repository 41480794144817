import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  ShowProps,
  RichTextField,
  TranslatableFields, TextField, TopToolbar
} from 'react-admin';

const ClientRefDetail = (props: ShowProps) => (
  <Show {...props} actions = {<TopToolbar />} >
    <SimpleShowLayout>
      <TranslatableFields locales={['sk', 'en']} defaultLocale={'sk'} >
        <TextField source="title.texts" label="resources.clientRefs.data.title"/>
        <RichTextField source="content.texts" label="resources.clientRefs.data.content"/>
      </TranslatableFields>      
    </SimpleShowLayout>
  </Show>
);

export default ClientRefDetail;
