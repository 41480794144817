import PartnersIcon from '@material-ui/icons/People';

import PartnersList from './PartnersList';
import PartnerEdit from './PartnerEdit';
import PartnerCreate from "./PartnerCreate";

export default {
    list: PartnersList,
    edit: PartnerEdit,
    create: PartnerCreate,
    icon: PartnersIcon,
};
