import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  ShowProps,
  RichTextField,
  TranslatableFields,
  TopToolbar
} from 'react-admin';

const PageDetail = (props: ShowProps) => (
  <Show {...props} actions = {<TopToolbar />} >
    <SimpleShowLayout>
      <TranslatableFields locales={['sk', 'en']} defaultLocale={'sk'} >
      <RichTextField source="content.texts" label="resources.pages.data.content"/>
      </TranslatableFields>
    </SimpleShowLayout>
  </Show>
);

export default PageDetail;
