import * as React from 'react';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    EditButton, BooleanField,
} from 'react-admin';
import {ResetPasswordButton} from "./ResetPasswordButton";

const UsersList = (props: ListProps) => (
    <List
        {...props}
        perPage={20}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField source='firstName'  label="resources.users.data.firstName"/>
            <TextField source='lastName'  label="resources.users.data.lastName"/>
            <TextField label='resources.users.data.username' source='username' />
            <TextField source='role'  label="resources.users.data.role"/>
            <TextField label='resources.users.data.msisdn' source='msisdn' />
            <TextField source='email'  label="resources.users.data.email"/>
            <TextField source='securityMethod'  label="resources.users.data.securityMethod"/>
            <TextField source='status'  label="resources.users.data.status"/>
            <DateField source='createdAt'  label="resources.users.data.createdAt"/>
            <DateField source='updatedAt'  label="resources.users.data.updatedAt"/>
            {props.permissions?.includes('ADMIN_RESET_USER_PASSWORD') && <ResetPasswordButton />}
            {props.permissions?.includes('ADMIN_EDIT_USER') && <EditButton />}
            {props.permissions?.includes('ADMIN_DELETE_USER') && <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);

export default UsersList;
