import * as React from 'react';
import { Redirect, Route } from 'react-router-dom'
import Configuration from './configuration/Configuration';
import Dashboard from './dashboard/Welcome'
import UserChangePassword from "./changePassword/UserChangePassword";

const PrivateRoute = ({ path, component: Component, ...rest }) => {
  let isLoggedIn = sessionStorage.getItem('token');
  if (isLoggedIn) {
    return <Route path={path} {...rest} render={(props) => <Component {...props} />} />
  }

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

export default [
  <PrivateRoute key='dashboard' exact path='/' component={Dashboard} />,
  <PrivateRoute key='dashboard' exact path='/no-rights' component={Dashboard} />,
  <PrivateRoute key='dashboard' exact path='/error' component={Dashboard} />,
  <PrivateRoute key='configuration' exact path='/configuration' component={Configuration} />,
];
