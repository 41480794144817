import * as React from 'react';
import { FunctionField } from 'react-admin';
import {
    Datagrid, DateField,
    List,
    ListProps,
    TextField,
    DeleteButton,
    BooleanField,
    EditButton,
} from 'react-admin';

const LinksList = (props: ListProps) => (
    <List
        {...props}
        perPage={20}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField source='code' label="resources.links.data.code" />
            <TextField source='section' label="resources.links.data.section" />
            <TextField source='link' label="resources.links.data.link" />
            <TextField source='status' label="resources.links.data.status" />
            <BooleanField source='internal' label="resources.links.data.internal" />
            <DateField source='createdAt' label="resources.links.data.createdAt" />
            <DateField source='updatedAt' label="resources.links.data.updatedAt" />
            {props.permissions?.includes('ADMIN_EDIT_LINK') && <EditButton />}
            {props.permissions?.includes('ADMIN_DELETE_LINK') && <DeleteButton undoable={false} />}
        </Datagrid>
    </List>
);

export default LinksList;
