import LinksIcon from '@material-ui/icons/Link';

import LinksList from './LinksList';
import LinkEdit from './LinkEdit';
import LinkCreate from "./LinkCreate";

export default {
    list: LinksList,
    edit: LinkEdit,
    create: LinkCreate,
    icon: LinksIcon,
};
